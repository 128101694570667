import { useNavigate } from '@remix-run/react';
import { $path } from 'remix-routes';

import {
  type DtoPage,
  type DtoPageSection,
  type EnumsPageName,
} from '@lp-lib/api-service-client/public';

import { useAppAnalytics } from '../../../src/analytics/app/identifiable';
import { useUGCAnalytics } from '../../../src/components/Game/UGC/utils';
import { useMyOrganizationFeatureChecker } from '../../../src/components/Organization';
import { useLiveAsyncCall } from '../../../src/hooks/useAsyncCall';
import { useFeatureQueryParam } from '../../../src/hooks/useFeatureQueryParam';
import { apiService } from '../../../src/services/api-service';
import { useNavigateToSection } from '../DynamicPage/PageSectionContainer';
import { PageDisplayName } from '../DynamicPage/utils';
import {
  PublicLibraryNav,
  PublicLibraryNavSearchBar,
} from './PublicLibraryNav';

export function PublicLibraryNavCreateCustomGame() {
  const featureChecker = useMyOrganizationFeatureChecker();
  const analytics = useUGCAnalytics();
  const ugcEnabled = useFeatureQueryParam('game-pack-ugc');
  const navigate = useNavigate();

  const {
    call: handleClick,
    state: {
      state: { isRunning },
    },
  } = useLiveAsyncCall(async () => {
    const resp = await apiService.gamePack.getGamePacksByCollection(
      'templates'
    );
    const packs = resp.data.gamePacks;
    const templateId = packs?.[0]?.id;
    const templateName = packs?.[0]?.name;

    analytics.trackCreateCustomGameClicked({
      packId: templateId,
      packName: templateName,
    });
    const params = new URLSearchParams(window.location.search);
    params.set('packId', templateId);
    navigate({
      search: params.toString(),
    });
  });

  if (!featureChecker.hasUnlimitedOndGameUGCAccess() && !ugcEnabled) {
    return null;
  }
  return (
    <div
      className={`bg-gradient-to-bl from-pairing-start to-pairing-end p-px rounded-xl`}
    >
      <button
        type='button'
        className='btn-secondary border-0 w-40 h-10 text-sms font-medium text-white 
          flex items-center justify-center gap-2.5 relative disabled:opacity-100'
        onClick={handleClick}
        disabled={isRunning}
      >
        Create Custom Game
      </button>
    </div>
  );
}

export function HomePublicLibraryNav(props: {
  pageName: EnumsPageName;
  page: DtoPage;
  isSectionSelected?: (section: DtoPageSection) => boolean;
}) {
  const { pageName, page, isSectionSelected } = props;

  const navigateToSection = useNavigateToSection($path('/home'));
  const analytics = useAppAnalytics();

  return (
    <PublicLibraryNav
      page={page}
      onSectionClick={(section, index) => {
        analytics.trackSectionNavClicked({
          pageName: PageDisplayName(pageName),
          sectionId: section.id,
          sectionTitle: section.title,
          index,
        });
        navigateToSection(section);
      }}
      isSectionSelected={isSectionSelected}
      actions={
        <div className='flex-none flex items-center gap-3'>
          <PublicLibraryNavSearchBar
            targetPath={$path('/home/search')}
            analytics={analytics}
          />
          <PublicLibraryNavCreateCustomGame />
        </div>
      }
      className='pr-5'
    />
  );
}
